<template>
  <div>
    <b-table
      v-if="relatedFiles.length"
      ref="refRelatedFilesTable"
      class="position-relative event_list shadow-table"
      :items="relatedFiles"
      responsive
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      no-sort-reset
      empty-text="No matching records found"
      :sort-desc.sync="isSortDirDesc"
    >
      <!-- Column: Role -->
      <template #cell(fileNo)="data">
        <div class="text-nowrap">
          <b-button
            v-if="canViewThisAction('show', 'File')"
            v-ripple.400="'rgba(30, 30, 30, 0.15)'"
            variant="link"
            class="weight-600 align-middle detail-view-id"
            :to="{ name: 'customers-files-show', params: { id: data.item.fileID } }"
          >
            {{ data.item.fileNo }}
          </b-button>
          <b-button
            v-else
            v-ripple.400="'rgba(30, 30, 30, 0.15)'"
            variant="none"
            class="text-bold-black align-middle detail-view-id not-button"
          >
            {{ data.item.fileNo }}
          </b-button>
        </div>
      </template>

      <template #cell(nvsFileType)="data">
        <div class="text-nowrap">
          <span class="">{{ data.item.nvsFileType }}</span>
          <br>
          <span
            v-if="data.item.lotLocation"
            class=""
          >
            {{ data.item.lotLocation }}
          </span>
        </div>
      </template>

      <template #cell(purchasers)="data">
        <div
          v-for="(purchaser, key) in data.item.purchasers"
          :key="key"
          class=""
        >
          <b-button
            v-if="canViewThisAction('show', 'Customer') && purchaser.customer"
            v-ripple.400="'rgba(30, 30, 30, 0.15)'"
            variant="link"
            class="weight-600 align-middle detail-view-id"
            :to="{ name: 'customers-customers-show', params: { id: purchaser.customer } }"
          >
            {{ purchaser.name }}
          </b-button>
          <b-button
            v-else
            v-ripple.400="'rgba(30, 30, 30, 0.15)'"
            variant="none"
            class="text-bold-black align-middle not-button"
          >
            {{ purchaser.name }}
          </b-button>
          <br>
          <span
            v-if="purchaser.nric && !Array.isArray(purchaser.nric)"
            class=""
          >
            {{ purchaser.nric[0] + purchaser.nric.slice(1).replace(/.(?=.{4,}$)/g, '*') }}
          </span>
        </div>
      </template>

      <!-- Column: Status -->
      <template #cell(nvsFileStatus)="data">
        <b-badge
          :variant="`light-${resolveFileStatusVariant(data.item.nvsFileStatus)}`"
          class="text-capitalize"
        >
          {{ data.item.nvsFileStatus }}
        </b-badge>
      </template>

      <template #cell(bookingDate)="data">
        <div class="text-nowrap">
          <span class="">{{ data.item.bookingDate ? dateFormat(data.item.bookingDate) : '-' }}</span>
        </div>
      </template>

      <template #cell(confirmDate)="data">
        <div class="text-nowrap">
          <span class="">{{ data.item.confirmDate ? dateFormat(data.item.confirmDate) : '-' }}</span>
        </div>
      </template>

      <template #cell(createdAt)="data">
        <div class="text-nowrap">
          <span class="">{{ data.item.createdBy }}</span>
          <br>
          <span class="">{{ data.item.createdAt ? dateFormatWithFormatNTZ(data.item.createdAt, 'DD/MM/YYYY, hh:mma') : '-' }}</span>
        </div>
      </template>

      <!-- Column: Actions -->
      <template
        v-if="canViewThisAction('show', 'File')"
        #cell(actions)="data"
      >
        <b-dropdown
          variant="link"
          no-caret
          :right="$store.state.appConfig.isRTL"
        >

          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>

          <b-dropdown-item
            v-if="canViewThisAction('show', 'File')"
            :to="{ name: 'customers-files-show', params: { id: data.item.fileID } }"
          >
            <span class="align-middle ml-50">View</span>
          </b-dropdown-item>
          <b-dropdown-item @click="openEmailStatementModal(data.item)">
            <span class="align-middle ml-50">Email to Customer</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>
    <div
      v-else
      class="empty_block"
    >
      <h4>No related files found.</h4>
    </div>

    <b-modal
      id="email-statement-modal"
      ref="email-statement-modal"
      modal-class="status-update-modal sign-modal-adjust sign-form_modal-adjust"
      centered
      no-stacking
      no-close-on-backdrop
      header-class="front-modal"
      title="Email Statement of Account to Customer"
      @ok="sendEmailStatement"
      @cancel="closeStatementModal"
    >
      <div class="">
        <b-form-group
          label="Customer Name"
          label-for="customer-name"
          class="mb-20"
        >
          <validation-provider
            #default="{ errors }"
            name="Customer Name"
            vid="customer-name"
            rules=""
          >
            <b-form-input
              id="customer-name"
              v-model="customerNameVal"
              placeholder="Lin Chai"
              class="mb-0"
              readonly
              :state="(errors.length > 0) ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          label="Email Address"
          label-for="customer-email"
          class="mb-20"
        >
          <validation-provider
            #default="{ errors }"
            name="Email Address"
            vid="customer-email"
            rules="required"
          >
            <b-form-input
              id="customer-email"
              v-model="customerEmailVal"
              placeholder="email@domain.com"
              class="mb-0"
              :readonly="hasEmailAddress ? true : false"
              :state="(errors.length > 0) ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          label="Message"
          label-for="statement-message"
          class="mb-20"
        >
          <validation-provider
            #default="{ errors }"
            name="Message"
            vid="statement-message"
            rules="required"
          >
            <b-form-textarea
              id="statement-message"
              v-model="statementMessage"
              placeholder="Statement of Account"
              class="mb-0"
              rows="9"
              :state="(errors.length > 0) ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </div>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="flat-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="success"
          class="custom-icon"
          :disabled="!customerEmailVal"
          @click="ok()"
        >
          <feather-icon
            icon="SendIcon"
            size="16"
            class="mr-50"
          />
          <span class="align-middle">Send</span>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import {
  BTable, BButton, VBTooltip, BBadge, BDropdown, BDropdownItem, BFormInput, BFormGroup, BFormTextarea,
} from 'bootstrap-vue'
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'
import moment from 'moment'
import { useUtils as useAclUtils } from '@/libs/acl/custom'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ToastificationContentCustom from '../../operation/ToastificationContentCustom.vue'

const { canViewThisAction } = useAclUtils()

export default {
  components: {
    BTable,
    BButton,
    BBadge,
    BDropdown,
    BDropdownItem,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    ValidationProvider,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    relatedFiles: {
      type: [Array, null],
      default: () => [],
    },
  },
  data() {
    return {
      user: store.state.auth.userData,
      render: false,
      sortBy: 'id',
      sortDesc: false,
      isSortDirDesc: true,
      tableItems: [],
      currentPage: 1,
      totalRows: 0,
      perPage: 10,
      customerNameVal: '',
      customerEmailVal: '',
      hasEmailAddress: '',
      customerContactVal: '',
      statementMessage: '',
      fileType: '',
      fileNumber: '',
      fileID: '',
      tableColumns: [
        {
          key: 'fileNo',
          label: 'File No.',
          sortable: false,
        },
        {
          key: 'nvsFileType',
          label: 'Product Description',
          sortable: false,
        },
        {
          key: 'purchasers',
          label: 'customer',
          sortable: false,
        },
        {
          key: 'nvsFileStatus',
          label: 'file status',
          sortable: false,
        },
        {
          key: 'bookingDate',
          label: 'booking date',
          sortable: false,
        },
        {
          key: 'confirmDate',
          label: 'confirm date',
          sortable: false,
        },
        {
          key: 'createdAt',
          label: 'Created By',
          sortable: true,
        },
        { key: 'actions' },
      ],
    }
  },

  methods: {
    canViewThisAction,
    resolveFileStatusVariant(status) {
      if (status === 'OPEN') return 'warning'
      if (status === 'BOOK') return 'info'
      return 'success'
    },
    openEmailStatementModal(item) {
      if (item.purchasers && item.purchasers.length) {
        const firstPurchaser = item.purchasers[0]
        this.hasEmailAddress = firstPurchaser.email ? firstPurchaser.email : ''
        this.customerNameVal = firstPurchaser.name ? firstPurchaser.name : ''
        this.customerEmailVal = firstPurchaser.email ? firstPurchaser.email : ''
        this.customerContactVal = firstPurchaser.contact ? firstPurchaser.contact : ''
        this.fileType = (item.file && item.nvsFileType) ? item.nvsFileType : ''
        this.fileNumber = item.fileNo ? item.fileNo : ''
        this.fileID = item.fileID ? item.fileID : ''
        this.statementMessage = `Here is the Statement of Account for your purchase with us for ${this.fileType} (file no.: ${item.fileNo}), as at ${moment().format('DD MMM YYYY')}. The statement provides a detailed summary of your transactions and current account balance.

Please be informed that the PDF is password-protected using your mobile number (e.g. 912345678).`
        this.$root.$emit('bv::show::modal', 'email-statement-modal', '')
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Purchaser Data is missing',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    },
    closeStatementModal() {
      this.$root.$emit('bv::hide::modal', 'email-statement-modal', '')
    },
    sendEmailStatement() {
      let contact = this.customerEmailVal
      if (this.customerContactVal) {
        contact = this.customerContactVal.slice(-8)
      }
      const formData = new FormData()
      formData.append('customerName', this.customerNameVal)
      formData.append('customerEmail', this.customerEmailVal)
      formData.append('message', this.statementMessage)
      formData.append('fileType', this.fileType)
      formData.append('fileNo', this.fileNumber)
      formData.append('contact', contact)
      formData.append('fileID', this.fileID)
      formData.append('url', `${window.location.origin}`)

      this.$http.post('customer/files/send/soa/statement', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(response => {
          if (response.data.success) {
            this.$toast({
              component: ToastificationContentCustom,
              props: {
                title: 'Statement of Account Email Sent',
                text: response.data.message,
                icon: 'CheckCircleIcon',
                variant: 'success',
                hideClose: true,
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>
